@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
}
* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-document url-prefix() {
  html {
    scrollbar-width: none;
  }
}

body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none; /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
  overflow-x: clip;

  font-family: "Roboto";
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}

body{
  background-color: #F8F9FA !important;
}


@media (max-width: 1200px) {
  body{
    /*width: fit-content;*/
  }
}

.kep-login-facebook{
  width: 100% !important;
  border: 1px solid #E7E9F5 !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  background: white !important;
  color: #414141 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 13px 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}


