.supportBlock{
    background-color: #1cbfff;
    text-align: center;
    font-size: 2vh;
    line-height: 5vh; 
}
.supportBlock_link{
    text-decoration: none !important;
    border: none !important;
    color: #fffb1c;
}
@media (max-width: 760px) {
    .supportBlock{
        font-size: 12px;
        line-height: normal;
    }
    
}
@media (min-width: 768px) and (max-width: 1024px) {
    .supportBlock{
        line-height: 3vw;
    }
}